
const initialState = {
  tenderRecord: [],
  categoryRecord: [],
  organizationRecord: [],
  citiesRecord: [],
  settingsRecord:[]
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TENDER_RECORD':
      return { ...state, tenderRecord: action.payload };
    case 'SET_CATEGORY_RECORD':
      return { ...state, categoryRecord: action.payload };
    case 'SET_ORGANIZATION_RECORD':
      return { ...state, organizationRecord: action.payload };
    case 'SET_CITIES_RECORD':
      return { ...state, citiesRecord: action.payload };
      case 'SET_SETTINGS_RECORD':
        return { ...state, settingsRecord: action.payload };  
    default:
      return state;
  }
};

export default rootReducer;