import axios from 'axios';
import BASE_URL from './api';

const api = axios.create({
  baseURL: BASE_URL,
});
export const fetchData = async (endPoint) => {
    try {
         const response = await api.get(endPoint);
         return response.data;
    } catch (error) {
        throw error
    }
};
export const createData = async (endPoint, body) => {
     try {
        const response = await api.post(endPoint, body);
        return response.data;
     } catch (error) {
        console.log(error)
     }
}