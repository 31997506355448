import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { fetchData } from "./api/api/apiService";
import './App.css';
const Category = lazy(() => import('./pages/Category'));
const Organization = lazy(() => import('./pages/Organization'));
const Tender = lazy(() => import('./pages/Tender'));
const Cities = lazy(() => import('./pages/Cities'));
const About = lazy(() => import('./pages/About'));
const Blogs = lazy(() => import('./pages/Blogs'));
const Contact = lazy(() => import('./pages/Contact'));
const Form = lazy(() => import('./pages/Form'));
const Policy = lazy(() => import('./pages/Policy'));
const Subscription = lazy(() => import('./pages/Subscription'));
const TenderDetail = lazy(() => import('./pages/TenderDetail'));
const Home = lazy(() => import("./pages/Home"));
// const NotFound = lazy(() => import("./pages/NotFound"));
const CitiesDetail = lazy(() => import('./pages/CitiesDetail'));
const CategoryDetail = lazy(() => import("./pages/CategoryDetail"));
const OrganizationDetail = lazy(() => import("./pages/OrganizationDetail"));

function App() {
  const dispatch = useDispatch();
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    getSettings();
    getTender();
    getOrganization();
    getCategory();
    getCity();
  }, [dispatch]);

  const fetchDataWithLoader = async (endpoint, actionType) => {
    try {
      setIsLoader(true);
      const result = await fetchData(endpoint);
      dispatch({ type: actionType, payload: result.data });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoader(false);
    }
  };

  const getTender = () => fetchDataWithLoader('/tender', 'SET_TENDER_RECORD');
  const getCategory = () => fetchDataWithLoader('/categories', 'SET_CATEGORY_RECORD');
  const getOrganization = () => fetchDataWithLoader('/organizations', 'SET_ORGANIZATION_RECORD');
  const getCity = () => fetchDataWithLoader('/cities', 'SET_CITIES_RECORD');
  const getSettings = () => fetchDataWithLoader('/settings', 'SET_SETTINGS_RECORD');

  return (
    <BrowserRouter>
      {isLoader && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      )}
      <Suspense fallback={<CircularProgress />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/category" element={<Category />} />
          <Route path="/category/:categoryName" element={<CategoryDetail />} />
          <Route path="/organization" element={<Organization />} />
          <Route path="/organization/:organizationName" element={<OrganizationDetail />} />
          <Route path="/tender" element={<Tender />} />
          <Route path="/tender/:id" element={<TenderDetail />} />
          <Route path="/cities" element={<Cities />} />
          <Route path="/cities/:cityName" element={<CitiesDetail />} />
          <Route path="/about" element={<About />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/form" element={<Form />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
